
































































































































































































































































































































import RegisterModel from "./index";
export default RegisterModel;
