var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("backHead"),
      _c(
        "div",
        { staticClass: "loginbox" },
        [
          _c("div", { staticClass: "headetitle" }, [_vm._v("手机号注册")]),
          _c(
            "el-form",
            {
              ref: "RegisterForm",
              staticClass: "demo-ruleForm",
              attrs: {
                autoComplete: "on",
                model: _vm.RegisterForm,
                rules: _vm.loginRules
              }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "inputstyle", attrs: { prop: "phone" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "tel",
                          placeholder: "请输入手机号码",
                          maxlength: "11"
                        },
                        model: {
                          value: _vm.RegisterForm.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.RegisterForm, "phone", $$v)
                          },
                          expression: "RegisterForm.phone"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { staticClass: "inputstyle rel", attrs: { prop: "captcha" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "tel",
                      placeholder: "请输入验证码",
                      maxlength: "6"
                    },
                    model: {
                      value: _vm.RegisterForm.captcha,
                      callback: function($$v) {
                        _vm.$set(_vm.RegisterForm, "captcha", $$v)
                      },
                      expression: "RegisterForm.captcha"
                    }
                  }),
                  _c(
                    "b",
                    {
                      staticClass: "getCode",
                      on: {
                        click: function($event) {
                          return _vm.getCode("RegisterForm")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.phonetime.time))]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inputstyle rel",
                  attrs: { prop: "referrerCode" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "没有推荐码请留空",
                      maxlength: "6"
                    },
                    model: {
                      value: _vm.RegisterForm.referrerCode,
                      callback: function($$v) {
                        _vm.$set(_vm.RegisterForm, "referrerCode", $$v)
                      },
                      expression: "RegisterForm.referrerCode"
                    }
                  }),
                  _c("b", {
                    staticClass: "getreferrerCode el-icon-warning-outline",
                    on: { click: _vm.getReferrerCode }
                  })
                ],
                1
              ),
              _vm.RegisterForm.captcha
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "inputstyle  onlybox",
                      attrs: { prop: "password" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入6-20位密码",
                          maxlength: "20"
                        },
                        model: {
                          value: _vm.RegisterForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.RegisterForm, "password", $$v)
                          },
                          expression: "RegisterForm.password"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "flex flexbox" }, [
            _c(
              "div",
              [
                _c("el-checkbox", {
                  staticStyle: { top: "5px" },
                  on: { change: _vm.check },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                })
              ],
              1
            ),
            _c("div", [
              _c("span", { staticClass: "registerRule" }, [
                _vm._v("注册即视为已阅读并同意 "),
                _c(
                  "b",
                  {
                    on: {
                      click: function($event) {
                        return _vm.showrule("REGISTER")
                      }
                    }
                  },
                  [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户注册协议》")]
                ),
                _c(
                  "b",
                  {
                    on: {
                      click: function($event) {
                        return _vm.showrule("PRIVACY_POLICY")
                      }
                    }
                  },
                  [_vm._v("《" + _vm._s(_vm.GLOBAL) + "用户隐私政策》")]
                )
              ])
            ])
          ]),
          _c(
            "el-button",
            {
              staticClass: "btn_red",
              style: { background: _vm.btnShow ? "#FF2D2E" : "#ffd5d5" },
              on: {
                click: function($event) {
                  return _vm.handleRegister("RegisterForm")
                }
              }
            },
            [_vm._v("注册")]
          )
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: {
            type: "alert",
            title: "",
            width: "80%",
            "show-cancel-button": ""
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("div", { staticClass: "opopbox" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.msg) } })
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false },
          model: {
            value: _vm.registerSuccess,
            callback: function($$v) {
              _vm.registerSuccess = $$v
            },
            expression: "registerSuccess"
          }
        },
        [
          _c("div", { staticClass: "img" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pic_success@2x.png"),
                alt: ""
              }
            })
          ]),
          _c("div", { staticClass: "title" }, [
            _c("h3", [_vm._v("您已成功注册")]),
            _c("div", { staticClass: "text" }, [
              _vm._v("欢迎加入" + _vm._s(_vm.GLOBAL) + "大家庭")
            ])
          ]),
          _c("div", { staticClass: "explain" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 为了保障您交易流程的真实、安全、有效，需要您进行实名认证，如有任何疑问可以咨询在线客服！ "
              )
            ])
          ]),
          _c("div", { staticClass: "btn", on: { click: _vm.iKnow } }, [
            _vm._v("知道了")
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false },
          model: {
            value: _vm.tip,
            callback: function($$v) {
              _vm.tip = $$v
            },
            expression: "tip"
          }
        },
        [
          _c("div", { staticClass: "tipTitle" }, [
            _c("h3", [_vm._v(_vm._s(_vm.tipForm.title))])
          ]),
          _vm.tipForm.show
            ? _c("div", { staticClass: "tipExplain" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(_vm.tipForm.content) + " ")
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "tipBtn",
              on: {
                click: function($event) {
                  _vm.tip = false
                }
              }
            },
            [_vm._v("知道了")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }